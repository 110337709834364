import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  REPORT_CARD, REPORT_CARD_API_URL, 
  GET_ALL_REPORT_CARD_API_URL,
  GET_SINGLE_STUDENT_REPORT_CARD
} from 'constants/report-card/index';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ReportCardErrors       : {},
  permission: permission?.report_card ?? [],
  ReportCardShowMessage  : null,

  singleStudentReportCard: [],
  // filters: {},
};

export const getSingleStudentReportCard = createAsyncThunk("getSingleStudentReportCard", async (data) => {
    try {
      return await CommonService.createAndUpdate(data, REPORT_CARD_API_URL);    
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
    }
});


export const manageReportCardSlice = createSlice({
  name: REPORT_CARD,
  initialState,
  reducers: {
    // setFilterData: (state, action) => {
    //   state.filters = action.payload
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSingleStudentReportCard.pending, (state, action) => {
        // state.DrawerStatus = 0
        // state.ReportCardButtonSpinner = true;
      }).addCase(getSingleStudentReportCard.fulfilled, (state, action) => {
        // state.DrawerStatus = 0
        // state.ReportCardButtonSpinner = false;
        // state.ReportCardErrors = {}
      }).addCase(getSingleStudentReportCard.rejected, (state, action) => {
        // state.DrawerStatus = 1
        // state.ReportCardShowMessage = true;
        // state.ReportCardButtonSpinner = false;
        // state.ReportCardErrors = JSON.parse(action.error.message)
      });
  },
});

export const { 
  // setFilterData
} = manageReportCardSlice.actions;

export default manageReportCardSlice.reducer;
