import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {REPORT_CARD_BATCH, REPORT_CARD_BATCH_API_URL, GET_ALL_REPORT_CARD_BATCH_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ReportCardBatchErrors       : {},
  permission: permission?.report_card_batch ?? [],
  ReportCardBatchShowMessage  : null,
  ReportCardBatchResult       : [],
  ReportCardBatchButtonSpinner : false,
  ReportCardBatchTableLoading : true,
  ReportCardBatchAddDrawer    : false,
  ReportCardBatchEditData     : [],
  ReportCardBatchButtonAndModelLabel  : setLocale('reportcardbatch.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewReportCardBatchData: [],
    ViewReportCardBatchLoader: true,
    DrawerStatus: 0,
};

export const createReportCardBatch = createAsyncThunk(
    "createReportCardBatch",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, REPORT_CARD_BATCH_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getReportCardBatch = createAsyncThunk(
    "getReportCardBatch",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_REPORT_CARD_BATCH_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewReportCardBatch = createAsyncThunk(
  "viewReportCardBatch",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, REPORT_CARD_BATCH_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteReportCardBatch = createAsyncThunk(
  "deleteReportCardBatch",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, REPORT_CARD_BATCH_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageReportCardBatchSlice = createSlice({
  name: REPORT_CARD_BATCH,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ReportCardBatchErrors = {};
    },
    ReportCardBatchAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.ReportCardBatchButtonSpinner = false;
      }
      state.ReportCardBatchAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.ReportCardBatchEditData    = [];
      state.ReportCardBatchButtonAndModelLabel = setLocale('reportcardbatch.add');
    },
    ReportCardBatchEditWithDrawerStatus: (state, action) => {
      state.ReportCardBatchAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ReportCardBatchEditData = action.payload.data;
      state.ReportCardBatchButtonAndModelLabel = setLocale('reportcardbatch.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createReportCardBatch.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ReportCardBatchButtonSpinner = true;
      }).addCase(createReportCardBatch.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ReportCardBatchButtonSpinner = false;
        state.ReportCardBatchErrors = {}
      }).addCase(createReportCardBatch.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ReportCardBatchShowMessage = true;
        state.ReportCardBatchButtonSpinner = false;
        state.ReportCardBatchErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getReportCardBatch.pending, (state, action) => {
        state.ReportCardBatchButtonSpinner = true;
        state.ReportCardBatchTableLoading = true;
      }).addCase(getReportCardBatch.fulfilled, (state, action) => {
        state.ReportCardBatchButtonSpinner = false;
        state.ReportCardBatchTableLoading = false;
        state.ReportCardBatchResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getReportCardBatch.rejected, (state, action) => {
        state.ReportCardBatchShowMessage = true; // Set the showMessage flag to display the errors
        state.ReportCardBatchButtonSpinner = false;
        state.ReportCardBatchTableLoading = false;
        state.ReportCardBatchErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewReportCardBatch.pending, (state, action) => {
        state.ViewReportCardBatchLoader = true;
        state.ViewReportCardBatchData = [];
      }).addCase(viewReportCardBatch.fulfilled, (state, action) => {
        state.ViewReportCardBatchLoader = false;
        state.ViewReportCardBatchData = action.payload;
      }).addCase(viewReportCardBatch.rejected, (state, action) => {
        state.ViewReportCardBatchLoader = false;
        state.ViewReportCardBatchData = [];
      })
      .addCase(deleteReportCardBatch.pending, (state, action) => {
        state.ReportCardBatchTableLoading = true;
      }).addCase(deleteReportCardBatch.fulfilled, (state, action) => {
        state.ReportCardBatchTableLoading = false;
      }).addCase(deleteReportCardBatch.rejected, (state, action) => {
        state.ReportCardBatchTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, ReportCardBatchAddDrawerStatus, ReportCardBatchEditWithDrawerStatus, updateSortFilters } = manageReportCardBatchSlice.actions;

export default manageReportCardBatchSlice.reducer;
